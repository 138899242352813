import React from 'react';
import Stars from "./Stars";
import {useTranslation} from "react-i18next";
import {LandingEnum} from "../_business/LandingEnum";

export default function Comments() {
  const
    isLanding = [LandingEnum.accent].includes(window.landing),
    {t} =
      useTranslation(
        'common',
        {
          keyPrefix: `paywall.${isLanding ? window.landing + '.' : ''}info`
        }
      )

  // const A34 = getExperiment('A34')

  return (
    <div className={'items text-start'}>
      {
        t('comments', {returnObjects: true}).map(({message, date, flag, header}, index) => {
          return (
            <div key={index} className={'item block'}>
              <div className={'row wide'}>
                <Stars/>
                <div className={'note md fw-400'}>{date}</div>
              </div>
              <h4 className={'row sm'}>
                <span style={{fontSize: 20}}>{flag}</span>
                {header}
              </h4>

              <p className={'notice md'}>{message}</p>

              {
                // A34.value === 'A' &&
                // <div className={'row sm'}>
                //   <img src={require('../assets/img/face/' + avatar)} alt="" width={24} height={24} className={'ava'}/>
                //   <p className={'notice small'}>{contact}</p>
                // </div>
              }
            </div>
          )
        })
      }
    </div>
  )
}
