import React from 'react';
import Top from "./parts/Top";

import LinkButton from "./parts/LinkButton";
import {useNavigate} from "react-router-dom";
import {getItem, setStep} from "./utils/lib";
import {Trans, useTranslation} from "react-i18next";
import Bottom from "./parts/Bottom";
import Button from "./parts/Button";
import mixpanel from 'mixpanel-browser';

export default function Book({step, nextUrl, preload}) {
  const
    {t, i18n} = useTranslation('common', {keyPrefix: 'book'}),
    navigate = useNavigate()

  const price = getItem('price', null, window.sessionStorage)
  // const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (!window.localStorage.getItem('uid')) {
      navigate('/');
      return;
    } else if (!price) {
      navigate(nextUrl);
      return;
    }

    // setStep({
    //   ...step,
    //   // product: window.localStorage.getItem('product') || 'noname'
    // })

    window.sessionStorage.setItem('success', '1');
  }, [step]);

  const handleClick = () => {
    if (window.guideAdded || window.sessionStorage.getItem('guide_added') === '1') {
      console.warn('book skip click');
      navigate(nextUrl);
      // mixpanel.track('book skip click', {
      //   product: window.localStorage.getItem('product') || 'noname'
      // });
      return;
    }

    // mixpanel.track('book click', {
    //   // product: window.localStorage.getItem('product') || 'noname'
    // })
    setStep({...step, page: 'book click'})

    window.guideAdded = true;
    window.sessionStorage.setItem('guide_added', '1');

    // setIsLoading(true);

    fetch(
      `${process.env.REACT_APP_API_URI}web-payments/book`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: price.id,
          uid: window.localStorage.getItem('uid'),
          // transaction_id: window.localStorage.getItem('transaction_id')
        })
      }
    )
      .then(async r => {
        console.log('book checkout', r)

        let data = {};

        if (r.ok) {
          try {
            data = await r.json();
          } catch (ex) {
            console.error(ex);
            data.error = 'Exception: ' + ex.message;
          }
        } else {
          data.error = 'Status: ' + r.status + ' ' + r.statusText;
        }

        console.log(data)

        mixpanel.people.set({book_purchase: true})
        // mixpanel.track('book checkout', {
        //   // product: window.localStorage.getItem('product') || 'noname',
        //   ok: r.ok,
        //   ...data
        // })
        setStep({
          ...step, page: 'book checkout',
          ok: r.ok,
          ...data
        })
      })

    navigate(nextUrl)
  }

  if (!price)
    return

  // const A28 = i18n.resolvedLanguage === 'en' ? getExperiment('A28') : {value: 'A'};

  return (
    <div className={'wrap fixed'}>
      <Top type={'center'}>
        <h1 className={'h2'}>{t('h1')}</h1>
        <p className={'notice small'}>{t('notice')}</p>

        <div className={'row center rounded-2'}
             style={{
               alignItems: 'center', padding: 14,
               background: 'linear-gradient(79.63deg, #2072F3 7.74%, #391A6B 92.26%)'
            }}
        >
          <div className={'w-100'}>
            <img src={require(`./assets/i18/${i18n.resolvedLanguage}/img/book.png`)} alt="" width={160} height={160} className={'mw-100 mx-auto'}/>
          </div>
          <div>
            <ul className={'small text-start pre-line mb-2'} style={{width: 146}}>
            <Trans i18nKey="list" t={t}>
                <li>20+ guided voice trainings</li>
                <li>Expert-level tips and advices</li>
                <li>Train for just 3 minutes per day</li>
              </Trans>
            </ul>

            <div className={'row sm'}>
              <Trans i18nKey="tags" t={t}>
                <span className={'tag tag-btn-sm'}>e-book</span>
                <span className={'tag tag-btn-sm'}>pdf</span>
              </Trans>
            </div>
          </div>
        </div>

        <p className={'notice small mb-2'}>{t('special')}</p>

        <div className={'items w-100'}>
          <div className={'block-plan active'} style={{position: 'relative'}}>
            {
              price.promo &&
              <div className={'promo small'}><em>{price.promo}</em></div>
            }
            <div className={'item'}>
              <div className={'w-100 text-start'}>
                <strong>{price.name}</strong>
                <p>
                    <s className={'note lg'}>{price.currency} {price.full_price}</s>
                </p>
              </div>
              <div className={'nowrap'}>
                <sup>{price.currencySymbol}</sup>
                <big>{Math.trunc(price.price)}</big>
                <div>
                  <em>{Math.round(price.price % 1 * 100)}</em>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Button onClick={handleClick}>
          {t('btn')}
          {/*{isLoading && <span className="spinner ml-2"></span>}*/}
        </Button>
        <LinkButton variant={''} to={nextUrl}>{t('skip')}</LinkButton>
      </Top>

      <Bottom preload={preload}/>
    </div>
  )
}
