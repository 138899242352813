import React from 'react';

import mixpanel from 'mixpanel-browser';
import {dataFetch} from "../../utils/lib";
import {PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {trackCheckout, trackPurchase} from "../../utils/tracking";

function PayPalForm({data, nextUrl}) {
  // console.log('PayPalForm', data)

  window.paypalData = data;

  const [message, setMessage] = React.useState(null);
  const [{ isPending }] = usePayPalScriptReducer();
  const navigate = useNavigate();

  const options = {
    shape: 'pill',
    color: 'gold',
    layout: 'horizontal',
    label: 'subscribe',
    tagline: false,
  };

  const handleCreateSubscription = (dataSub, actions) => {
    // console.log('handleCreateSubscription', window.paypalData, dataSub, actions)

    mixpanel.register({
      type: 'Paypal',
      code: window.paypalData?.code
    })
    trackCheckout(window.paypalData)

    return actions.subscription.create({
      'plan_id': window.paypalData.code
      // 'plan_id': 'P-10D64727AM279094NMVK7VZY'
    })
      .then((orderId) => {
        mixpanel.track('payment paypal', {status: 'created'})

        dataFetch(
          process.env.REACT_APP_API_URI + 'web-payments',
          {...window.paypalData, paypalOrderId: orderId},
          (response) =>
            window.localStorage.setItem('transaction_id', response.transaction_id)
        )

        return orderId
      })
  }

  const handleApprove = (dataSub, actions) => {
    // console.log('onApprove', dataSub, actions)

    // status: 'approve',
    mixpanel.register({order_id: dataSub.orderID})
    mixpanel.people.set({paypal: dataSub.orderID, plan_purchase: true})
    trackPurchase(window.paypalData)

    navigate(nextUrl)
  }

  const handleError = (info) => {
    setMessage(info.message || 'Unexpected error');
    console.error('handleError', info);
    mixpanel.track('payment paypal', {status: 'error'});
  };

  if (isPending)
    return (
      <p className={'text-center w-100'}>
        <span className={'spinner'}></span>
        <br />
      </p>
    );

  return (
    <>
      <PayPalButtons className={'w-100'} style={options} createSubscription={handleCreateSubscription}
                     forceRerender={data?.fullPrice}
                     onApprove={handleApprove} onError={handleError}/>

      {message && <div id="payment-message" className={'w-100 pt-0'}>{message}</div>}
    </>
  );
}

export default function PaymentPayPal({data, nextUrl}) {
  const {i18n} = useTranslation();

  const options = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    // currency: 'USD',
    vault: true,
    intent: 'subscription',
    // components: 'buttons',
  };

  switch (i18n.resolvedLanguage) {
    case 'en':
      options.locale = 'en_US';
      break;

    case 'es':
      options.locale = 'es_ES';
      break;

    case 'de':
      options.locale = 'de_DE';
      break;
  }

  return (
    <PayPalScriptProvider options={options}>
      {/*<p className={'text-center mb-2 notice'}>Easier, faster and safer way to pay:</p>*/}
      <PayPalForm data={data} nextUrl={'/success'}/>
    </PayPalScriptProvider>
  );
}
