import React from 'react';
import Checkbox from "../Checkbox";
import {dataFetch} from "../../utils/lib";
import {Trans, useTranslation} from "react-i18next";
import Paysafe from "./Paysafe";

export default function Plan({children, index, discount, onChange, strict, listInput = [], path = 'prices', data, method = 'GET'}) {
  const {t, i18n} = useTranslation('common', {keyPrefix: 'paywall.plan'})

  const
    [list, setList] = React.useState(listInput),
    [active, setActive] = React.useState(listInput[0]),
    isForce = window.sessionStorage.getItem('force')

  // const A40 = getExperiment('A40')

  React.useEffect(() => {
    if (listInput.length)
      return

    dataFetch(
      `${process.env.REACT_APP_API_URI}web-payments/${path}?lang=${i18n.resolvedLanguage}${isForce ? '&force=1' : ''}`,
      data,
      (response) => {
        if (!Array.isArray(response))
          response = [response]

        setList(response)

        let isActive = false

        for (const item of response) {
          if (
            item.selected
            // item.selected && A40.value === 'A' ||
            // A40.value === 'B' && i === response.length - 1 // && item.period === '3month'
          ) {
            isActive = true
            setActive(item)

            if (onChange)
              onChange(item)

            break
          }
        }

        if (!isActive) {
          setActive(response[0])

          if (onChange)
            onChange(response[0])
        }
      },
      method
    )
  }, [i18n.resolvedLanguage])

  function handleChange(item) {
    setActive(item);

    if (onChange)
      onChange(item)
  }

  return (
    <>
      <div id={'plan'} className={'items w-100'} style={list?.[0]?.promo ? {marginTop: 10} : {}}>
        {
          !active &&
          <iframe title={'Loading...'} src={process.env.PUBLIC_URL + '/static/loader.html'} width="100%" height={74} frameBorder="0" style={discount ? {} : {margin: '94px 0'}}/>
        }
        {
          list.map((item) => {
            const checked = active?.id === item.id;
            // item.standardPrice = 19.9;

            return (
              <div key={item.id}
                   className={'block-plan' + (item.popular ? ' popular' : '') + (checked ? ' active' : '')}
                   style={{position: 'relative'}}
                   onClick={() => handleChange(item)}>
                {
                  item.promo &&
                  <div className={'promo small'}><em>{item.promo}</em></div>
                }
                <div className={'item'}>
                  {
                    list.length > 1 &&
                    <Checkbox type={'radio'} name={'plan' + (index ? '-' + index : '')} checked={checked} readOnly={true}/>
                  }
                  <div className={'w-100 text-start'}>
                    <strong>{item.name}</strong>
                    <p>
                      <em className={'small'}>{item.currency} {item.fullPrice}</em>&nbsp;
                      {
                        (item.standardPrice || item.price) &&
                        <s className={'note lg'}>{item.currency} {item.standardPrice || item.price}</s>
                      }
                    </p>
                  </div>
                  <div className={'nowrap'}>
                    <sup>{item.currencySymbol}</sup>
                    <big>{Math.trunc(item.dayPrice)}</big>
                    <div>
                      <Trans i18nKey="perDay" t={t}>
                        <em>{{dayPrice: Math.round(item.dayPrice % 1 * 100)}}</em>
                        <small>PER DAY</small>
                      </Trans>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      {children}

      {
        !strict &&
        <>
          <Paysafe/>
          <img src={require('../../assets/img/paywall/logos.png')} alt="stripe" className={'mw-100'} width={326} height={22} style={{padding: '0 15px'}} />
        </>
      }
    </>
  )
}
