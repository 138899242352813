export const LanguageEnum = Object.freeze({
  en: 1,
  es: 2,
  ru: 3,
  uk: 4,
  de: 5,
  fr: 6,
})

export const LanguageKeysEnum = Object.freeze({
  en: 1,
  es: 2,
  de: 3,
  fr: 4,
  ru: 5,
  uk: 6,
})
